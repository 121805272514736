
import { defineComponent, ref, watch } from 'vue'
import MyHeader from '@/components/MyHeader.vue'
import CommonFooter from '@/components/CommonFooter.vue'
import MyPage from '@/components/MyPage.vue'
import DetailPage from './detail.vue'
// 首先的从vue-router中导入useRouter
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Solution',
  components: {
    MyHeader,
    MyPage,
    DetailPage,
    CommonFooter
  },
  setup() {
    // 实例化路由
    const router = useRouter()

    const solutionModule: string[] = ['全部', '智慧建筑', '智慧管廊', '智慧仓储']
    const currnetSolutionModuleIndex = ref(0)
    const solutionList: any = ref([])

    const view = ref('list')
    view.value = router.currentRoute.value.query.type ? 'detail' : 'list'
    // 路由变化时，刷新数据
    watch(router.currentRoute, () => {
      view.value = router.currentRoute.value.query.type ? 'detail' : 'list'
    })

    const buildingSolutionList: object[] = [
      { img: require('@/assets/index/solution_building1.png'), key: 'building', title: '智慧建筑解决方案', content: '以Turbo-BOSS智能建筑管理平台为核心，简化了前端硬件设备的种类，功能集中以软件形态的虚拟化方式体现。' },
      { img: require('@/assets/index/solution_building8.png'), key: 'garden', title: '智慧园区综合解决方案', content: '实现了多信息协同联动，有效的降低了企业运营成本，提高工作效率，加强各类园区创新、服务和管理能力。' },
      { img: require('@/assets/index/solution_building6.png'), key: 'school', title: '智慧学校综合解决方案', content: '促进学校安全管理和应急处置的精细化、专业化和及时性。促进校园生活和教学智能化、便捷化' },
      { img: require('@/assets/index/solution_building3.png'), key: 'engine-room', title: '智慧机房监控系统解决方案', content: '通过5G拥抱物联网，基于5G+IOT+ESIM新兴技术，实现落地动环监控物联网5G的解决方案。' },
      { img: require('@/assets/index/solution_building4.png'), key: 'parking', title: '智慧停车场综合解决方案', content: '为了解决“停车难”问题，捍御者专门研发一套智慧、高效的无卡、无人值守停车场管理解决方案。' },
      { img: require('@/assets/index/solution_building5.png'), key: 'library', title: '智慧图书馆综合解决方案', content: '利用多种网络互连，、从而实现图书馆信息资源服务提供与读者用户知识需求之间的“智能化互动”' },
      { img: require('@/assets/index/solution_building7.png'), key: 'hospital', title: '智慧医院综合解决方案', content: '实现医院分级诊疗，资源共享，实现医院内部各个子系统的互联互通，高效运转，提升用户体验，降低差错。' },
      { img: require('@/assets/index/solution_building2.png'), key: 'park', title: '智慧公园综合解决方案', content: '智慧公园建设从顶层设计以“服务游客”为中心，围绕公园的建设要求和总体规划，结合国内一流、国际先进的设计理念。' }
    ]
    const pipeSolutionList: object[] = [
      { img: require('@/assets/index/solution_pipe1.png'), key: 'safety', title: '封闭空间融合安防通信系统解决方案', content: '针对封闭空间的安防、监控、通信管理的特征,建立统一的服务中间件，标准化接口，融合集成。' }
    ]
    const storeSolutionList: object[] = [
      { img: require('@/assets/index/solution_store1.png'), key: 'store', title: '智能仓储管理解决方案', content: '务流程，涵盖货物入库、质检、上架、维修保养、移库、出库等业务流程。' }
    ]
    // 智慧交通暂时先去掉，下面的数据不需要了
    const trafficSolutionList: object[] = [
      { img: require('@/assets/index/solution_traffic1.png'), key: 'metro', title: '地铁综合监控系统解决方案', content: '结合公司在远程控制等方面的优势，融合了捍御者核心技术和产品功能，提供给客户完整的综合监控解决方案。' },
      { img: require('@/assets/index/solution_traffic2.png'), key: 'monitor', title: '高速公路监控方案', content: '捍御者高速公路监控系统，满足国家智慧高速规划，提供高速公路全程监控的自动化管理工具。' }
    ]
    const allSolutionList: object[] = [
      { img: require('@/assets/index/solution_building1.png'), key: 'building', title: '智慧建筑解决方案', content: '以Turbo-BOSS智能建筑管理平台为核心，简化了前端硬件设备的种类，功能集中以软件形态的虚拟化方式体现。' },
      { img: require('@/assets/index/solution_pipe1.png'), key: 'safety', title: '封闭空间融合安防通信系统解决方案', content: '针对封闭空间的安防、监控、通信管理的特征,建立统一的服务中间件，标准化接口，融合集成。' },
      { img: require('@/assets/index/solution_building6.png'), key: 'school', title: '智慧学校综合解决方案', content: '促进学校安全管理和应急处置的精细化、专业化和及时性。促进校园生活和教学智能化、便捷化' },
      { img: require('@/assets/index/solution_building8.png'), key: 'garden', title: '智慧园区综合解决方案', content: '实现了多信息协同联动，有效的降低了企业运营成本，提高工作效率，加强各类园区创新、服务和管理能力。' },
      { img: require('@/assets/index/solution_building3.png'), key: 'engine-room', title: '智慧机房监控系统解决方案', content: '通过5G拥抱物联网，基于5G+IOT+ESIM新兴技术，实现落地动环监控物联网5G的解决方案。' },
      { img: require('@/assets/index/solution_building4.png'), key: 'parking', title: '智慧停车场综合解决方案', content: '为了解决“停车难”问题，捍御者专门研发一套智慧、高效的无卡、无人值守停车场管理解决方案。' },
      { img: require('@/assets/index/solution_building5.png'), key: 'library', title: '智慧图书馆综合解决方案', content: '利用多种网络互连，、从而实现图书馆信息资源服务提供与读者用户知识需求之间的“智能化互动”' },
      { img: require('@/assets/index/solution_building7.png'), key: 'hospital', title: '智慧医院综合解决方案', content: '实现医院分级诊疗，资源共享，实现医院内部各个子系统的互联互通，高效运转，提升用户体验，降低差错。' },
      { img: require('@/assets/index/solution_traffic1.png'), key: 'metro', title: '地铁综合监控系统解决方案', content: '结合公司在远程控制等方面的优势，融合了捍御者核心技术和产品功能，提供给客户完整的综合监控解决方案。' },
      { img: require('@/assets/index/solution_traffic2.png'), key: 'monitor', title: '高速公路监控方案', content: '捍御者高速公路监控系统，满足国家智慧高速规划，提供高速公路全程监控的自动化管理工具。' },
      { img: require('@/assets/index/solution_store1.png'), key: 'store', title: '智能仓储管理解决方案', content: '务流程，涵盖货物入库、质检、上架、维修保养、移库、出库等业务流程。' },
      { img: require('@/assets/index/solution_building2.png'), key: 'park', title: '智慧公园综合解决方案', content: '智慧公园建设从顶层设计以“服务游客”为中心，围绕公园的建设要求和总体规划，结合国内一流、国际先进的设计理念。' }
    ]
    const currentSolutionPage = ref(1)
    solutionList.value = allSolutionList

    /**
     * @description 修改产品模块当前页码
     * @author 林育宏
     * @date 2020-10-28
     */
    function changeCurrentPage(index: number) {
      currentSolutionPage.value = index // 修改当前页码
    }

    // 当前模块变化时， 页码回到1开始
    watch(currnetSolutionModuleIndex, () => {
      solutionList.value = currnetSolutionModuleIndex.value === 0 ? allSolutionList : currnetSolutionModuleIndex.value === 1 ? buildingSolutionList : currnetSolutionModuleIndex.value === 2 ? pipeSolutionList : currnetSolutionModuleIndex.value === 3 ? storeSolutionList : trafficSolutionList
      currentSolutionPage.value = 1 // 修改当前页码
    })

    // 跳转到解决方案模块详情页
    function jumpSolutionDetail(key: string) {
      router.push({ 
        path: 'solution', 
        query: { type: key }
      })
    }

    function returnList() {
      view.value = 'list'
    }

    return {
      view, // 当前页面视图
      returnList, // 返回列表页
      solutionModule, // 当前解决方案模块列表
      currnetSolutionModuleIndex, // 解决方案模块当前项
      currentSolutionPage, // 当前页码
      changeCurrentPage, // 修改页码
      jumpSolutionDetail, // 跳转到详情页
      solutionList // 右侧产品列表
    }
  }
})
